import React from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H2, H3 } from 'primitives/appTitle';
import AppSection from 'primitives/appSection';
import AppLinkBar from 'primitives/appLinkBar';

import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import HeroCover from 'components/hero/heroCover';
import Highlights from 'components/highlights';
import GalleryGrid from 'components/gallery/galleryGrid';
import BannerGradient from 'components/banner/bannerGradient';
import { AppButton } from 'primitives/appButton';
import { FrontMatterportMessages } from './matterportPageTranslations';
import { ShText, useShIntlLocale } from '@shoootin/translations';
import {
  heroCover,
  PrestationFigures,
  prestationHighlights,
  PrestationInNav,
  PrestationProsTabSet,
} from './matterportPageValues';
import { FrontCommonMessages } from '../../../appCommonTranslations';

// @ts-ignore
// @ts-ignore
import { device, enhanceHeader } from 'helpers/helpers';

import { Sticky, StickyContainer } from 'react-sticky';
import '../frontPrestation.less';
import PrestationOthers from '../prestationOthers';
import { getStickyStyle } from '../prestationCommons';
import PrestationTabsetPros from '../prestationPagePros';
import PrestationPageFigures from '../prestationPageFigures';
import CoverMatterport from '../../../components/coverMatterport';
import { graphql } from 'gatsby';
import { ServiceMatterportPageQuery } from '../../../appGraphQLTypes';
import { getFluidImages } from '../../../appGatsbyImageUtils';
import usePrestationMainImages from '../../../queries/usePrestationMainImages';
import { useServiceProfessionPrices } from '../servicePrices';
import CoverProfessionPrices from '../../../components/coverProfessionPrices';
import Usecase from '../../../components/usecase';

// don't forget to set this to correct prestation Messages.
const SpecificPageMessages = FrontMatterportMessages;

const MatterportPage = ({ data }: { data: ServiceMatterportPageQuery }) => {
  const heroImage = usePrestationMainImages().matterport;

  const professionPrices = useServiceProfessionPrices('matterport', [
    'residentialRealEstate',
    'commercialRealEstate',
    'architect',
    'hotel',
    'restaurant',
  ]);

  const locale = useShIntlLocale();
  const useCase = locale === ('fr_FR' || 'fr_BE') ? 'mom' : 'tollbrothers';

  return (
    <MainLayout className="page-prestation" pageName="prestation_matterport">
      <HeroCover image={heroImage} values={heroCover} />

      <StickyContainer>
        <div className="section prestation-intro" id="intro">
          <div className="container">
            <Sticky>
              {({ style, isSticky }) => {
                const stickyStyle = getStickyStyle(isSticky);
                return (
                  <nav
                    id="linknav"
                    className={isSticky ? 'fixed' : ''}
                    style={{
                      ...style,
                      ...stickyStyle,
                    }}
                  >
                    <AppLinkBar
                      links={PrestationInNav}
                      scroll={true}
                      modifier="swiper-container-horizontal swiper-container-free-mode"
                    />
                  </nav>
                );
              }}
            </Sticky>

            {/* <ShRow>
              <ShCol sm={{ span: 40, offset: 10 }} xs={60}>
                <CoverPhotoGatsbyImage
                  fluid={data.coverIntroImage!.childImageSharp!.fluid!}
                />
              </ShCol>
            </ShRow> */}
          </div>
          <Highlights highlights={prestationHighlights} />

          <CoverMatterport path="https://my.matterport.com/show/?m=YJQgvq8apaf" />
          {/* <Cover */}
          {/* path={require('images/background/banner-matterport.jpg')} */}
          {/* target="#" */}
          {/* mode="matterport" */}
          {/* /> */}

          <PrestationPageFigures
            figures={PrestationFigures}
            images={getFluidImages(data, 'figureImages')}
            coverCaption={SpecificPageMessages.coverCaption}
          />
        </div>

        <AppSection
          id="pros"
          header={
            <H2>
              <ShText message={SpecificPageMessages.advantagesTitle} />
            </H2>
          }
        >
          <PrestationTabsetPros
            nav={PrestationProsTabSet}
            quarterMode="matterport"
            images={getFluidImages(data, 'benefitsImages')}
          />
        </AppSection>

        <AppSection
          className="grid-gallery"
          id="gallery"
          header={
            <H2>
              <ShText message={FrontCommonMessages.gallery} />
            </H2>
          }
        >
          <GalleryGrid />
        </AppSection>

        <AppSection
          className="section-usecase"
          id="usecase"
          header={
            <H2>
              <ShText message={FrontCommonMessages.usecase} />
            </H2>
          }
        >
          <Usecase name={useCase} />
        </AppSection>

        <AppSection
          className="prestation-price"
          id="price"
          header={
            <H2>
              <ShText message={SpecificPageMessages.priceTitle1} />
              <br />
              <ShText message={SpecificPageMessages.priceTitle2} />
            </H2>
          }
        >
          <div key="cover-prices">
            <CoverProfessionPrices professionPrices={professionPrices} />
          </div>
        </AppSection>
      </StickyContainer>
      <BannerGradient className="banner--gradient-matterport">
        <H3>
          <ShText message={SpecificPageMessages.bannerTitle} />
        </H3>
        <p>
          <ShText message={SpecificPageMessages.bannerP} />
        </p>
        <AppInternalLink to={AppInternalPaths.order}>
          <AppButton modifier={['light', 'large']}>
            <ShText message={SpecificPageMessages.bannerCta} />
          </AppButton>
        </AppInternalLink>
      </BannerGradient>

      <PrestationOthers prestationName="matterport" />
    </MainLayout>
  );
};

export default MatterportPage;

export const query = graphql`
  query ServiceMatterportPage {
    #coverIntroImage: file(
    #  relativePath: { eq: "prestation_matterport-avantages-intro.jpg" }
    #) {
    #  ...PrestationCoverIntroImageFragment
    #}
    figureImages: allFile(
      sort: { fields: name, order: ASC }
      filter: {
        relativePath: { glob: "prestation_matterport-avantages-service-*.jpg" }
      }
    ) {
      edges {
        node {
          ...PrestationFigureImageFragment
        }
      }
    }
    benefitsImages: allFile(
      sort: { fields: name, order: ASC }
      filter: {
        relativePath: { glob: "prestation_matterport-avantages-*.jpg" }
      }
    ) {
      edges {
        node {
          ...PrestationBenefitsImageFragment
        }
      }
    }
  }
`;
