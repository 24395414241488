import React from 'react';
import { LinkData } from '../appTypes';
import AppButtonBar from '../primitives/appButtonBar';
import useIntersectionObserverInitializer from '../hooks/useIntersectionObserverInitializer';
import useRequestIdleCallbackInitializer from '../hooks/useRequestIdleCallbackInitializer';

const CoverMatterport = ({
  path,
  nav,
}: {
  path: string;

  nav?: LinkData[];
}) => {
  const initializer = useRequestIdleCallbackInitializer();
  return (
    <div className="matterport-cover">
      <iframe
        width="853"
        height="480"
        allowFullScreen
        src={
          // We lazy-init the matterport iframe as it's less critical than other assets
          initializer.isInitialized ? path : ''
        }
      />
      {nav && nav.length && (
        <div className="matterport-cover-nav">
          <AppButtonBar links={nav} />
        </div>
      )}
    </div>
  );
};

export default CoverMatterport;
