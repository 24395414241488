import { defineMessages } from 'react-intl';

const prefix = 'front_prestation_matterport';

export const FrontMatterportMessages = defineMessages({
  //begin hero
  heroTitle: {
    id: `${prefix}_heroTitle`,
    defaultMessage: 'Nos prestations de Matterport',
  },
  heroP: {
    id: `${prefix}_heroP`,
    defaultMessage:
      "Donnez vie à vos locaux avec des expériences immersives et bénéficiez automatiquement de tous les atouts marketing dont vous avez besoin, à partir d'un seul tournage.",
  },
  //end hero

  // begin highlights

  highlightsPanels_1_title: {
    id: `${prefix}_highlightsPanels_1_title`,
    defaultMessage: 'Accessible',
  },
  highlightsPanels_1_p1: {
    id: `${prefix}_highlightsPanels_1_p1`,
    defaultMessage:
      'Partagez et visionnez partout, grâce à la création de pages dédiées avec des outils d’analyse.',
  },
  highlightsPanels_2_title: {
    id: `${prefix}_highlightsPanels_2_title`,
    defaultMessage: 'Leadership',
  },
  highlightsPanels_2_p1: {
    id: `${prefix}_highlightsPanels_2_p1`,
    defaultMessage:
      'Prenez un temps d’avance  sur vos concurrents, grâce à nos prix les plus compétitifs du marché.',
  },
  highlightsPanels_3_title: {
    id: `${prefix}_highlightsPanels_3_title`,
    defaultMessage: 'Unique',
  },
  highlightsPanels_3_p1: {
    id: `${prefix}_highlightsPanels_3_p1`,
    defaultMessage:
      'Une technologie de visite immersive unique au monde, pour une expérience inégalée.',
  },
  // end highlights

  coverCaption: {
    id: `${prefix}_coverCaption`,
    defaultMessage:
      'Recevez de véritables panoramas horizontaux et verticaux, pour créer une réelle expérience immersive.',
  },

  nav1: {
    id: `${prefix}_nav1`,
    defaultMessage: 'Intro',
  },
  nav2: {
    id: `${prefix}_nav2`,
    defaultMessage: 'Avantages',
  },
  nav3: {
    id: `${prefix}_nav3`,
    defaultMessage: 'Galerie',
  },
  nav4: {
    id: `${prefix}_nav4`,
    defaultMessage: 'Étude de cas',
  },

  figure1Label: {
    id: `${prefix}_figure1Label`,
    defaultMessage: 'Dollhouse',
  },
  figure1Text: {
    id: `${prefix}_figure1Text`,
    defaultMessage:
      'Dollhouse est une expérience en ligne permettant aux acheteurs de se déplacer dans une propriété pour la voir sous tous les angles. Ce différent point de vue donne aux acheteurs une perspective totalement unique de la propriété.',
  },
  figure2Label: {
    id: `${prefix}_figure2Label`,
    defaultMessage: 'Floorplans',
  },
  figure2Text: {
    id: `${prefix}_figure2Text`,
    defaultMessage:
      "Alors que les vendeurs sont séduits par la visite virtuelle immersive, ils s'attendent toujours à ce que vous utilisiez des méthodes de marketing traditionnelles comme des plans d'étage. Les acheteurs (et surtout les nouveaux propriétaires) sont venus à dépendre des plans d'étage pour visualiser l'espace.",
  },

  //begin avantages
  advantagesTitle: {
    id: `${prefix}_advantagesTitle`,
    defaultMessage: 'Les avantages',
  },

  advantages1Title: {
    id: `${prefix}_advantages1Title`,
    defaultMessage: 'Prix compétitif',
  },

  advantages1P1: {
    id: `${prefix}_advantages1P1`,
    defaultMessage: 'Avantages 1 p1',
  },

  advantages1P2: {
    id: `${prefix}_advantages1P2`,
    defaultMessage: 'Avantages 1 p2',
  },

  advantages2Title: {
    id: `${prefix}_advantages2Title`,
    defaultMessage: 'Pages dédiées',
  },

  advantages2P1: {
    id: `${prefix}_advantages2P1`,
    defaultMessage: 'Avantages 2 p1',
  },

  advantages2P2: {
    id: `${prefix}_advantages2P2`,
    defaultMessage: 'Avantages 2 p2',
  },

  advantages3Title: {
    id: `${prefix}_advantages3Title`,
    defaultMessage: 'Outils d’analyse',
  },

  advantages3P1: {
    id: `${prefix}_advantages3P1`,
    defaultMessage: 'Avantages 3 p1',
  },

  advantages3P2: {
    id: `${prefix}_advantages3P2`,
    defaultMessage: 'Avantages 3 p2',
  },

  advantages4Title: {
    id: `${prefix}_advantages4Title`,
    defaultMessage: 'Outils d’analyse',
  },

  advantages4P1: {
    id: `${prefix}_advantages4P1`,
    defaultMessage: 'Avantages 4 p1',
  },

  advantages4P2: {
    id: `${prefix}_advantages4P2`,
    defaultMessage: 'Avantages 4 p2',
  },

  advantages5Title: {
    id: `${prefix}_advantages5Title`,
    defaultMessage: '3D',
  },

  advantages5P1: {
    id: `${prefix}_advantages5P1`,
    defaultMessage: 'Avantages 5 p1',
  },

  advantages5P2: {
    id: `${prefix}_advantages5P2`,
    defaultMessage: 'Avantages 5 p2',
  },

  advantages6Title: {
    id: `${prefix}_advantages6Title`,
    defaultMessage: 'Commande et confirmation',
  },

  advantages6P1: {
    id: `${prefix}_advantages6P1`,
    defaultMessage: 'Avantages 6 p1',
  },

  advantages6P2: {
    id: `${prefix}_advantages6P2`,
    defaultMessage: 'Avantages 6 p2',
  },

  //End avantages

  //begin banner

  bannerTitle: {
    id: `${prefix}_bannerTitle`,
    defaultMessage:
      'Commander votre reportage Matterport à partir de 80€, et recevez votre confirmation immédiatement.',
  },
  bannerP: {
    id: `${prefix}_bannerP`,
    defaultMessage:
      'Notre offre comprend tous les outils marketing et d’analyse nécessaire à mettre en avant vos flooplans & dollhouse.',
  },
  bannerCta: {
    id: `${prefix}_bannerCta`,
    defaultMessage: 'Commander',
  },

  //end banner

  priceTitle1: {
    id: `${prefix}_priceTitle1`,
    defaultMessage: 'Découvrez les tarifs du Matterport',
  },
  priceTitle2: {
    id: `${prefix}_priceTitle2`,
    defaultMessage: 'pour votre métier',
  },

  //price
});
