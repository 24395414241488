import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { LinkData } from '../appTypes';

const AppButtonBar = ({ links }: { links: LinkData[] }) => (
  <div className="buttonbar">
    {links.map((link, i) => (
      <a key={i} href={link.href}>
        {link.label}
      </a>
    ))}
  </div>
);

export default AppButtonBar;
